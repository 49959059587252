<template>
  <div>
    <bis-date-picker
      v-bind="datePickerProps"
      v-model="trimmedValue"
      :displayFormat="displayFormat"
      :modelFormat="modelFormat"
      :textFieldProps="textFieldProps"
      :datePickerProps="datePickerProps"
      :menuProps="menuProps"
      :disabled="disabled"
      @input="menu = false"
      :allowEmpty="allowEmpty"
      :error="error"
      :errorMessages="errorMessages"
      :aliases="aliases"
      :label="label"
      :readonly="readonly"
    />
  </div>
</template>

<script>
import bisDatePicker from "@web-components/bisdatepicker";

export default {
  components: {
    bisDatePicker
  },
  props: {
    menuProps: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    },
    textFieldProps: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    },
    datePickerProps: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    },
    value: { required: true },
    readonly: { type: Boolean, default: false },
    label: { type: String },
    disabled: { type: Boolean, default: false },
    modelFormat: { type: [String], default: "YYYY-MM-DD" },
    allowEmpty: {
      type: [Boolean],
      default: true
    },
    displayFormat: { type: String, default: "MM/DD/YYYY" },
    aliases: { type: Object, default: () => ({}) },
    error: { type: Boolean },
    errorMessages: { type: Array, default: () => [] }
  },

  data() {
    return {};
  },
  computed: {
    trimmedValue: {
      get() {
        if (
          ![null, undefined].includes(this.value) &&
          !this.modelFormat.includes("T") &&
          this.value.includes("T")
        ) {
          return this.value.split("T")[0];
        }
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
